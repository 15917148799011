<template>
  <div v-cloak>

      <span v-if="$route.name == 'CryptoDresses'">
        <h3>Crypto Dresses</h3>
        <p>
          All-over print dresses have cryptocurrency logos printed all over them. The design is continuous from one side to another.
        </p>

        <!-- <p>All-over printing is a printing process where the design is printed on paper with dye ink and then transferred directly onto the fabric with heat. All-over printing allows to cover all of the garment with the design instead of, for example, DTG printing that has a smaller area to work with.</p> -->
        
        <p>This is a quick showcase of the most interesting crypto dresses available on Crypto Clothing. For more, see individual coin pages.</p>
        
        <div v-for="coin in cryptoDresses" :key="coin" style="margin-top:-20px;">
          <div style="height:65px;clear:both;"></div>
          <CryptoDress :coin="coin" :available="false"/>
        </div>
      </span>


      <span v-if="$route.name == 'CryptoTshirts'">
        <h3>Crypto T-Shirts</h3>
        <p>
          Cryptocurrency t-shirts with high-quality logo prints.
        </p>

        <p>
          A classic t-shirt that compliments any outfit. It's made of a heavier cotton and the double-stitched neckline and sleeves give it more durability, so it can become an everyday favorite.
        </p>

        <p>
          Below are listed only the most popular cryptocurrency t-shirts. You can find more crypto t-shirts on invidual coin pages.
        </p>

        <div v-for="coin in cryptoTop" :key="coin" style="margin-top:-20px;">
          <div style="height:65px;clear:both;"></div>
          <CryptoTshirt :coin="coin" :available="true" />
        </div>
      </span>


      <span v-if="$route.name == 'CryptoHoodies'">
        <h3>Crypto Hoodies</h3>
        <p>
          These are your favorite hooded cryptocurrency sweatshirts. They are soft, smooth and stylish. Perfect for cooler evenings.
        </p>
        
        <div v-for="coin in cryptoTop" :key="coin" style="margin-top:-20px;">
          <div style="height:65px;clear:both;"></div>
          <CryptoHoodie :coin="coin" :available="true" />
        </div>
      </span>


      <span v-if="$route.name == 'CryptoSweatshirts'">
        <h3>Crypto Sweatshirts</h3>
        <p>
          This well-loved Gildan 18000 Unisex Sweatshirt is the perfect addition to any wardrobe. It has a crew neck, and it's made from air-jet spun yarn and quarter-turned fabric, which eliminates a center crease, reduces pilling, and gives the sweatshirt a soft, comfortable feel.
        </p>
        
        <div v-for="coin in cryptoTop" :key="coin" style="margin-top:-20px;">
          <div style="height:65px;clear:both;"></div>
          <CryptoSweatshirt :coin="coin" :available="true" />
        </div>
      </span>


      <span v-if="$route.name == 'CryptoLongsleeveTshirts'">
        <h3>Crypto Longsleeve T-Shirts</h3>
        <p>
          This Unisex Long Sleeve Tee is an instant crowd favorite! It can be styled with jeans for a casual look, or worn with a blazer for a more classy business look while remaining in comfort. Made from 100% cotton and featuring a classic crew neck, it's a must-have in every wardrobe!
        </p>
        
        <div v-for="coin in cryptoTop" :key="coin" style="margin-top:-20px;">
          <div style="height:65px;clear:both;"></div>
          <CryptoLongsleeveTshirt :coin="coin" :available="true" />
        </div>
      </span>


      <span v-if="$route.name == 'CryptoLaptopSleeves'">
        <h3>Crypto Laptop Sleeves (Cases)</h3>
        <p>
          This lightweight, form-fitting laptop sleeve is a must-have for any laptop owner on the go. To prevent any scratches, it contains a padded zipper binding and its interior is lined with faux fur. What’s more, it’s made from a water-resistant and scratch-proof material, making sure that both the laptop and the sleeve design are intact from day to day.
        </p>
        
        <div v-for="coin in cryptoTop" :key="coin" style="margin-top:-20px;">
          <div style="height:65px;clear:both;"></div>
          <CryptoLaptopSleeve :coin="coin" :available="true" />
        </div>
      </span>


      <span v-if="$route.name == 'CryptoMugs'">
        <h3>Crypto Mugs</h3>
        <p>
          A cryptocurrency mug is perfect for your morning coffee, afternoon tea, or whatever hot beverage you enjoy. It's glossy white and yields vivid prints that retain their quality when dish-washed and microwaved.
        </p>

        <p>
          Below is listed only a small part of all crypto mugs available on Crypto Clothing. You can find more crypto mugs on invidual coin pages.
        </p>
        
        <div v-for="coin in cryptoTop" :key="coin" style="margin-top:-20px;">
          <div style="height:65px;clear:both;"></div>
          <CryptoCoffeeMug :coin="coin" :available="true" />
        </div>
      </span>


      <span v-if="$route.name == 'CryptoStickers'">
        <h3>Crypto Stickers</h3>

        <p>
          Vinyl cryptocurrency stickers for hundreds of most popular crypto coins and tokens.
        </p>

        <p>
          Below is listed only a small part of all crypto stickers available on Crypto Clothing. You can find more stickers on invidual coin pages.
        </p>

        <div v-for="coin in cryptoStickers" :key="coin" style="margin-top:-20px;">
          <div style="height:65px;clear:both;"></div>
          <CryptoSticker :coin="coin" :available="true" />
        </div>
      </span>
      

      <span v-if="$route.name == 'CryptoBackpacks'">
        <h3>Crypto Backpacks</h3>
        <p>
          A spacious crypto backpack for people on the go! An all-over print minimalist backpack that's made from a water-resistant material. It has a large inside pocket for a laptop, and a hidden pocket that's perfect for keeping valuable items safe and accessible while traveling.
        </p>

        <p>All-over printing is a printing process where the design is printed on paper with dye ink and then transferred directly onto the fabric with heat. All-over printing allows to cover all of the garment with the design instead of, for example, DTG printing that has a smaller area to work with.</p>
        
        <div v-for="coin in cryptoBackpacks" :key="coin" style="margin-top:-20px;">
          <div style="height:65px;clear:both;"></div>
          <CryptoBackpack :coin="coin" :available="true" />
        </div>
      </span>


      <span v-if="$route.name == 'CryptoMasks'">
        <h3>Crypto Face Masks</h3>
        <p>
          These all-over print neck gaiters with cryptocurrency logos printed all over them can be used as a face covering, headband, bandana, wristband, balaclava, and neck warmer.
        </p>

        <p>All-over printing is a printing process where the design is printed on paper with dye ink and then transferred directly onto the fabric with heat. All-over printing allows to cover all of the garment with the design instead of, for example, DTG printing that has a smaller area to work with.</p>
        
        <div v-for="coin in cryptoMasks" :key="coin" style="margin-top:-20px;">
          <div style="height:65px;clear:both;"></div>
          <CryptoMask :coin="coin" :available="true" />
        </div>
      </span>


      <!-- <span v-if="$route.name == 'CryptoFashion' || $route.name == 'CryptoLogosPoster' || $route.name == 'CryptoToteBag' || $route.name == 'CryptoLaptopSleeve'">
        <CryptoFashion />
      </span> -->


      <div style="clear:both;height:80px;"></div>
      <div style="border-bottom:1px solid #b1b1b1;"></div>
      <div style="clear:both;height:20px;"></div>

      <!-- <div v-if="$route.name == 'CryptoToteBag' || $route.name == 'CryptoLaptopSleeve' || $route.name == 'CryptoLogosPoster'">
        See more: <router-link to="/crypto-fashion">Crypto Fashion</router-link>
      </div>
      <div v-else>
        See more: <router-link to="/merch">All Cryptocurrency Clothing</router-link>
      </div> -->

  </div>
</template>

<script>
import CryptoTshirt from "./CryptoTshirt.vue";
import CryptoLongsleeveTshirt from "./CryptoLongsleeveTshirt.vue";
import CryptoDress from "./CryptoDress.vue";
import CryptoHoodie from "./CryptoHoodie.vue";
import CryptoSweatshirt from "./CryptoSweatshirt.vue";
import CryptoLaptopSleeve from "./CryptoLaptopSleeve.vue";
import CryptoCoffeeMug from "./CryptoCoffeeMug.vue";
import CryptoSticker from "./CryptoSticker.vue";
import CryptoBackpack from "./CryptoBackpack.vue";
import CryptoMask from "./CryptoMask.vue";

export default {
  components: {
    CryptoDress,
    CryptoLongsleeveTshirt,
    CryptoLaptopSleeve,
    CryptoTshirt,
    CryptoHoodie,
    CryptoSweatshirt,
    CryptoCoffeeMug,
    CryptoSticker,
    CryptoBackpack,
    CryptoMask,
    // CryptoFashion
  },

  data() {
    return {
      // title: 'Loading...'
      inner: '',
      // cryptoHodlTshirt: [ 'bitcoin', 'ethereum', 'xrp', 'litecoin', 'stellar', 'monero', 'uniswap', 'yearn-finance' ],


      // cryptoDresses: [ 'bitcoin', 'ethereum', 'xrp', 'cardano', 'tron', 'stellar', 'monero', 'chainlink', 'iota', 'maker', 'paxos-standard', 'qtum', 'algorand', 'nano', 'theta', 'rsk-infrastructure-framework', 'stratis', 'dragonchain', 'fetch', 'tierion', 'power-ledger', 'newton', 'ocean-protocol', 'orbs', 'request', 'kin', 'agrello-delta', 'bean-cash', 'tigercash', 'kanadecoin' ],
      // cryptoBackpacks: ['bitcoin', 'ethereum', 'litecoin', 'eos', 'dash', 'stellar', 'tron', 'cardano', 'nexo', 'request', 'airswap', 'peercoin', 'dragon-coins', 'dogecoin', 'dragonchain'],
      // cryptoMasks: ['bitcoin', 'ethereum', 'xrp', 'litecoin', 'binance-coin', 'cardano', 'stellar', 'tron', 'chainlink', 'insolar', 'ocean-protocol', 'augur', 'dash', 'decentraland', 'dogecoin', 'ethereum-classic', 'foam', 'grin', 'iota', 'monero', 'nexo', 'numeraire', 'power-ledger', 'qtum', 'request'],
      // cryptoTop: ['bitcoin', 'ethereum', 'xrp', 'bitcoin-cash', 'bitcoin-sv', 'tether', 'eos', 'litecoin', 'binance-coin', 'ethereum-classic', 'cardano', 'tron', 'stellar', 'monero', 'dash', 'tezos', 'chainlink', 'iota', 'hedgetrade', 'zcash', 'basic-attention-token', 'dogecoin', 'paxos-standard', 'qtum', 'nexo', 'steem', 'rsk-infrastructure-framework', 'decentraland', 'dragonchain', 'request'],
      // cryptoStickers: ['bitcoin', 'ethereum', 'xrp', 'bitcoin-cash', 'bitcoin-sv', 'tether', 'eos', 'litecoin', 'binance-coin', 'ethereum-classic', 'cardano', 'tron', 'stellar', 'monero', 'dash', 'tezos', 'iota', 'hedgetrade', 'zcash', 'basic-attention-token', 'dogecoin', 'paxos-standard', 'qtum', 'nexo', 'dragon-coins', 'decentraland', 'dragonchain', 'request'],

      cryptoDresses: [ 'bitcoin', 'ethereum', 'xrp', 'cardano', 'tron', 'stellar', 'monero', 'chainlink', 'iota', 'maker', 'paxos-standard', 'qtum', 'algorand', 'theta-network', 'stratis', 'dragonchain', 'ocean-protocol', 'orbs', 'request', 'kin', 'agrello-delta', 'bean-cash', 'kanadecoin' ],
      cryptoBackpacks: ['bitcoin', 'ethereum', 'litecoin', 'eos', 'dash', 'stellar', 'tron', 'cardano', 'request', 'airswap', 'peercoin', 'dogecoin'],
      cryptoMasks: ['bitcoin', 'ethereum', 'xrp', 'litecoin', 'binance-coin', 'cardano', 'stellar', 'tron', 'chainlink', 'ocean-protocol', 'dash', 'decentraland', 'dogecoin', 'ethereum-classic', 'grin', 'iota', 'monero',  'numeraire', 'request'],
      cryptoTop: ['bitcoin', 'ethereum', 'xrp', 'bitcoin-cash', 'eos', 'litecoin', 'ethereum-classic', 'cardano', 'tron', 'stellar', 'monero', 'dash', 'chainlink', 'iota', 'zcash', 'basic-attention-token', 'dogecoin', 'steem', 'decentraland', 'request'],
      cryptoStickers: ['bitcoin', 'ethereum', 'xrp', 'bitcoin-cash', 'eos', 'litecoin', 'ethereum-classic', 'cardano', 'tron', 'stellar', 'monero', 'dash', 'iota', 'zcash', 'basic-attention-token', 'dogecoin', 'decentraland', 'request'],
      cc_coins: cc_coins
    }
  },

  mounted() {

  },

};
</script>

<style scoped>

  p { 
    font-size: 14px;
  }

  a {
    border-bottom: 1px solid grey;
  }
</style>