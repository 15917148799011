<template>
  <div class="header">
    
    <!-- <div class="top"></div> -->

    <div style="max-width:640px;margin:0 auto;">
        
      <!-- Left side -->
      <div style="float:left;margin-top:15px;">

        <div @click="toTop()"  style="float:left;">

          <router-link class="logo header-hover" to="/" style="display:inline-block;float:left;">
            Crypto Logos
          </router-link>

        </div>
      </div>

      <div class="header-right-side">

        <router-link to="/about" class="header-link" style="margin-left:0px;">About</router-link>

        <router-link to="/faq" class="header-link">FAQ</router-link>

        <router-link to="/history" class="header-link" >History</router-link>

        <router-link to="/guides" class="header-link">Guides</router-link>
        
        <!-- <span @click="toTopOnly()">
          <router-link to="/merch" class="header-link">Merch</router-link>
        </span> -->
        <!-- <a href="https://api.cryptologos.cc" target="_blank" class="header-link">API</a> -->



        <!-- <router-link to="/merch" class="header-link">Merch</router-link> -->

        <!-- <router-link style="margin-top:15px;text-align:center;" to="/about">
          <div style="width:77px;height:18px;padding-top:6px;" class="header-button">
            ABOUT
          </div>
        </router-link> -->

        <router-link v-if="shoppingCount > 0 || ($route.name != 'CryptoLogos' && $route.name != 'about' && $route.name != 'history' && $route.name != 'guides' && $route.name != 'faq' && $route.name != 'CryptoLogo')" to="/shopping-cart" title="Shopping Cart" style="position:relative;float:right;margin-top:-4px;width:32px;margin-left:12px;">
          <div style="vertical-align:middle;float:left;" class="header-hover-svg">
            <svg width="35" height="30" viewBox="0 -31 512.00033 512">
              <path d="m166 300.003906h271.003906c6.710938 0 12.597656-4.4375 14.414063-10.882812l60.003906-210.003906c1.289063-4.527344.40625-9.390626-2.433594-13.152344-2.84375-3.75-7.265625-5.964844-11.984375-5.964844h-365.632812l-10.722656-48.25c-1.523438-6.871094-7.617188-11.75-14.648438-11.75h-91c-8.289062 0-15 6.710938-15 15 0 8.292969 6.710938 15 15 15h78.960938l54.167968 243.75c-15.9375 6.929688-27.128906 22.792969-27.128906 41.253906 0 24.8125 20.1875 45 45 45h271.003906c8.292969 0 15-6.707031 15-15 0-8.289062-6.707031-15-15-15h-271.003906c-8.261719 0-15-6.722656-15-15s6.738281-15 15-15zm0 0" /><path d="m151 405.003906c0 24.816406 20.1875 45 45.003906 45 24.8125 0 45-20.183594 45-45 0-24.8125-20.1875-45-45-45-24.816406 0-45.003906 20.1875-45.003906 45zm0 0" /><path d="m362.003906 405.003906c0 24.816406 20.1875 45 45 45 24.816406 0 45-20.183594 45-45 0-24.8125-20.183594-45-45-45-24.8125 0-45 20.1875-45 45zm0 0" />
            </svg>
          </div>
          <div style="position:absolute;top:1px;color:#666;width:20px;text-align:center;padding-left:10px;float:left;font-weight:600;font-size:12px;pointer-events:none;">
            {{ shoppingCount }}
          </div>
        </router-link>
      </div>

    </div>

  </div>
</template>

<script>

  export default {

    data() {
      return {
        homePath: homePath,
      }
    },

    methods: {
      toTop: function() {
        window.scrollTo(0,0);
        if (this.$parent.shoppingCart) {
          this.$router.push(this.homePath);
        }
      },
      toTopOnly() {
        window.scrollTo(0,0);
      }
    },

    mounted: function() {

    },

    props: ['shoppingCount'],
    
  }

</script>

<style>

.faq-btn {
  width:16px;
  height:16px;
  border-radius:50%;
  background-color:white;
  color:#3D666A;
  font-weight:bold;
  text-align:center;
  font-size:13px;
}

.faq-btn:hover {
  background-color:#e3ffff;
}

.button-new {
  float:right;
  width:22px;
  height:22px;
  margin-right:30px;
  margin-top:21px;
}

.header {
  margin-bottom: 80px;
}

.store-icon {
  width: 41px;
  margin-top: -3px;
  margin-right: 10px;
}

@media screen and (max-width: 600px) {
  .store-icon {
    margin-right: 5px;
  }
}

.header-right-side {
  float:right;
  /* max-width:200px; */
  text-align:right;
  margin-top:19px;
}

.header-link {
  color: white!important;
}

.header-link:hover {
  color: white!important;
  text-decoration: underline;
  /* border-bottom: 1px solid white; */
}

.header-link {
  margin-left:20px;
}

@media screen and (max-width: 460px) {
  .header-link {
    margin-left:15px;
  }

  .header-right-side {
    clear:both;
    float: left;
    margin-top:15px;
  }

  .header {
    height: 97px!important;
  }
}


.header {
  margin-bottom: 60px;
}

@media only screen and (max-width: 680px) {
  .header {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 50px;
  }
  .logo {
    margin-left: 0px;
  }
}

</style>