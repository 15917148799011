<template>
  <div v-cloak>
    <template>

      <span v-if="this.$route.name == 'nft'">

        <h3>NFT</h3>

        <div style="height:2px;"></div>

        <p>CryptoLogos.cc NFT collection consist of AI generated logo interpretations by famous artists.</p>

      </span>

      
      <span v-if="this.$route.name == 'faq'">

        <h3>
          FAQ
        </h3>

        <div style="height:25px;"></div>

        <p>
          <b>
            How to submit a new logo to Crypto Logos?
          </b>
        </p>

        <p>
          To submit a new logo for a cryptocurrency or a token, please  <a href="mailto:feedback@cryptologos.cc?subject=New logo submission" class="underline" >send us</a> the SVG vector file format of the logo. To be included on Crypto Logos, the token needs to be listed on <a href="https://coinmarketcap.com" target="_blank">CoinMarketCap</a>.
        </p>

        <div style="height:25px;"></div>

        <p>
          <b>
            How to update a logo on Crypto Logos?
          </b>
        </p>

        <p>
          If you wish to update an existing logo, e.g. add additional logo versions, include notes or links to branding guidelines, etc. Please <a href="mailto:feedback@cryptologos.cc?subject=Update logo">contact us</a> by email with your request.
        </p>
        
        <div style="height:25px;"></div>
        
        <p>
          <b>
            How to remove a logo from Crypto Logos?
          </b>
        </p>

        <p>
          If you wish to remove a logo from CryptoLogos.cc collection, please <a href="mailto:feedback@cryptologos.cc?subject=Remove logo">contact us</a> with your claim. We will remove the logo in a timely manner, according to the official claim or branding guidelines.
        </p>
        
        
        <div style="height:25px;"></div>

        <p>
          <b>
            How to download all logos at once?
          </b>
        </p>

        <p>
          You can download all logos at once by downloading the ZIP archive from our <a href="https://github.com/coinwink/crypto-logos-cc" target="_blank">GitHub repository</a>.
        </p>

        <div style="height:25px;"></div>

        <p>
          <b>
            How to support Crypto Logos?
          </b>
        </p>

        <p>
          You can support Crypto Logos by <a href="https://commerce.coinbase.com/checkout/d814ac60-b00c-432f-be67-953b9b5d8c7a" target="_blank">donating</a>, or by checking out the <a href="https://coinwink.com" target="_blank">Coinwink</a> app, which is a valuable tool that helps to track crypto market and saves time.
        </p>

        <div style="height:25px;"></div>

        <p>
          <b>
            What are the differences between SVG and PNG file formats?
          </b>
        </p>

        <p>
          SVG and PNG are two common file formats used for images. The main difference between the two is the way they represent graphics. PNG files are made up of pixels, while SVG files are made up of vectors.
          <br><br>
          Pixels are tiny squares of color that are arranged in a grid to create an image. When you zoom in on a PNG image, you can see the individual pixels that make up the image. This is why PNG images can appear blurry or pixelated when they are resized or zoomed in.
          <br><br>
          Vectors, on the other hand, are made up of lines and shapes that are defined by mathematical equations. This means that SVG images can be scaled up or down without losing quality. When you zoom in on an SVG image, the lines and shapes remain sharp and clear, no matter how much you zoom in.
          <br><br>
          For this reason, SVG is generally considered the better option for images that need to be resized or scaled, such as logos or icons. Additionally, SVG files can be used as a source and easily converted to other formats, including PNG. PNG files, on the other hand, cannot be converted into vectors, so it's important to prioritize using SVG as a source when possible.
        </p>
        
        <div style="height:25px;"></div>

        <p>
          <b>
            Are cryptocurrency logos protected by intellectual property laws, or can they be used without restriction?
          </b>
        </p>

        <p>
          Check the official brand guidelines (brandbook) on the crypto project's website for logo usage information.
          <br><br>
          If no guidelines are available, contact the project team for permission.

<br><br>
        </p>



      </span>


      <span v-if="this.$route.name == 'about'">

        <h3>About</h3>

        <div style="height:2px;"></div>

        <p>
          Crypto Logos is a curated collection of professionally prepared high-quality cryptocurrency logo files that are easily accessible and free to download.
          <br><br>
          Crypto Logos disrupts the chaotic and disorganized approach to using cryptocurrency logos. This default approach not only wastes a lot of time but also often results in low-quality, wrong or completely missing logos. Crypto Logos presents a new and well-structured way to find, share and use cryptocurrency logos in a professional and simple way.
          <br><br>
          The mission of Crypto Logos is to save time and simplify the lives of designers, developers, marketers, journalists and anyone else who needs a high-quality and up-to-date logo file for his or her crypto project.
          <br><br>
          All logos are free to download in high-quality PNG and vector SVG file formats.
          <br><br>
          Crypto Logos collection is the biggest and most accurate high-quality cryptocurrency logos collection on the internet. Existing logos are being kept up to date and new logos are added regularly.
          <br><br>
          If you wish to support Crypto Logos, you can do this by <a href="https://commerce.coinbase.com/checkout/d814ac60-b00c-432f-be67-953b9b5d8c7a" target="_blank">donating</a>.
          <br><br>
          To update or submit a new logo, for feedback or general inquiries, please contact by <a href="mailto:feedback@cryptologos.cc">email</a>.

          <br><br>
        </p>

      </span>




      <span v-if="this.$route.name == 'history'">

        <h3>History of Cryptocurrency Logos</h3>

        <p>
          You are welcome to contribute to the Crypto Logos history page. <a v-bind:href="'mailto:feedback@cryptologos.cc?subject=History submission'" class="underline" >Send us</a> additional historical information (logo image, release year, info source, author if known), and we will include it on this page.
        </p>

        <div style="height:20px;"></div>

        <logo-history coin-id="bitcoin" />

        <logo-history coin-id="litecoin" />

        <logo-history coin-id="xrp" />

        <logo-history coin-id="stellar" />

        <logo-history coin-id="decentraland" />

        <logo-history coin-id="komodo" />

        <logo-history coin-id="bitcoin-sv" />

        <logo-history coin-id="chainlink" />

        <logo-history coin-id="dash" />

        <logo-history coin-id="loopring" />

        <logo-history coin-id="augur" />

        <logo-history coin-id="nano" />

        <logo-history coin-id="neo" />

        <logo-history coin-id="vertcoin" />

        <logo-history coin-id="nimiq" />

        <logo-history coin-id="particl" />

        <logo-history coin-id="quant" />

        <logo-history coin-id="polkadot-new" />

        <logo-history coin-id="alpha-finance-lab" />

        <logo-history coin-id="golem-network-tokens" />

        <logo-history coin-id="gnosis-gno" />

        <logo-history coin-id="monetha" />

        <logo-history coin-id="idex" />

        <logo-history coin-id="bnb" />

      </span>


      <span v-if="this.$route.name == 'guides'">

        <h3>Crypto Logo Guides</h3>

        <p>
          A list of crypto logo usage guidelines, style guides, brand books, official brand assets.
        </p>

        <p>
          Use these examples to better understand proper crypto logo usage guidelines, or for inspiration when creating your own branding.
        </p>

        <div style="height:10px;"></div>

        <h3>Brand Guidelines</h3>

        <p>
          <a href="/brandbooks/ethereum-brand-usage-guidelines.pdf" target="_blank">Ethereum (ETH) brand usage guidelines</a> (<a href="https://github.com/ethereum/ethereum-org/blob/master/public/images/logos/External-brand-usage-guidelines.pdf" target="_blank">source</a>)
        </p>

        <p><a href="/brandbooks/litecoin-brand-guidelines.pdf" target="_blank">Litecoin (LTC) brand guidelines</a> (<a href="https://litecoin-foundation.org/wp-content/uploads/2019/01/LC18-007-Brand-guidelines.pdf" target="_blank">source</a>)</p>

        <p>
          <a href="/brandbooks/enjin-brand-guidelines.pdf" target="_blank">Enjin (ENJ) brand guidelines</a> (<a href="https://enjin.io/about/press-kit" target="_blank">source</a>)
        </p>
        <p><a href="/brandbooks/tron-logo-guidelines.pdf" target="_blank">TRON (TRX) logo guidelines</a> (<a href="https://tron.guide/resources/tron-logo-guidelines/" target="_blank">source</a>)</p>
          <p><a href="/brandbooks/dash-style-guide.pdf" target="_blank">Dash (DASH) brand style guide</a> (<a href="https://media.dash.org/wp-content/uploads/dash_style_guide_2018.pdf" target="_blank">source</a>)</p>

        <p>
          <a href="https://tether.to/branding/" target="_blank">Tether (USDT) Brand Guidelines</a>
        </p>

        <p><a href="https://casper.network/network/trademark-policy" target="_blank">Casper (CSPR) Trademark Policy</a></p>

        <p><a href="https://e.cash/ecash-brand" target="_blank">eCash (XEC) Logo Kit & Brand Guidelines</a></p>

        <p>
          <a href="/brandbooks/neo-brand-book.pdf" target="_blank">Neo (NEO) brand book</a>
        </p>
          
        <p><a href="https://brand.ripple.com/article/xrp-symbol" target="_blank">XRP (XRP) Symbol Style Guide</a></p>

        <p><a href="https://www.stellar.org/brand-policy" target="_blank">Stellar (XLM) Brand Policy</a></p>

        <p><a href="/brandbooks/aptos-brand-guidelines.pdf" target="_blank">Aptos (APT) Brand Guidelines</a> (<a href="https://aptosfoundation.org/assets/Aptos_Brand_Guidelines_2022-e7d28242e2ac8173db4d7623df60c97aeaa46951.pdf" target="_blank">source</a>)</p>
        <div style="height:10px;"></div>

        <h3>Graphic Assets</h3>

          <p><a href="https://github.com/vertcoin-project/vertcoin-logo-package" target="_blank">Vertcoin (VTC) Logo Package</a></p>

        <p><a href="https://cardano.org/brand-assets/" target="_blank">Cardanno (ADA) Official Brand Assets</a></p>

          <p><a href="https://www.peercoin.net/resources#graphics" target="_blank">Peercoin (PPC) Official Brand Assets</a></p>

        <p><a href="https://tron.guide/resources/tron-logo-kit/" target="_blank">TRON (TRX) Logo Kit</a></p>

        <p>
          <a href="https://bitcoinbch.com/resources/logos" target="_blank">Official Bitcoin Cash (BCH) Logos</a>
        </p>

        <p>
          <a href="https://solana.com/press" target="_blank">Solana (SOL) Press Kit</a>
        </p>

        <div style="height:10px;"></div>
        
      </span>



<span v-if="this.$route.name == 'terms-and-conditions'">

<h3>Terms and Conditions</h3>

<p>Last updated: November 12, 2023</p>

<h4>1. Terms</h4>

<p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Crypto Logos (“we,” “us” or “our”), concerning your access to and use of the https://cryptologos.cc web app as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY. </p>

<p>Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms of Use to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.&nbsp;</p>

<p>The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>

<h4>2. The service</h4>

<p>Crypto Logos offers a streamlined solution for ordering brand promotional merchandise, simplifying the entire process for our users.</p>

<p>Our application facilitates an efficient ordering process by providing users with current and high-quality logo files, generating mockups, and offering pre-selected options for both clothing types and optimal cost-benefit models.</p>

<p>For this service Crypto Logos charges a fixed commision from every order. The commision is already included in all prices on this website.</p>

<p>Users must be 18 years or older to use Crypto Logos services.</p>

<h4>3. Use of copyrighted materials</h4>

<p>Crypto Logos neither owns nor claims any copyrights associated with the cryptocurrency logos displayed on our site. The copyrights for these logos are owned by their respective owners.</p>

<p>Most of products available at Crypto Logos are being used according to the fair-use principle or official branding guidelines of individual crypto projects. We recognize that these projects often encourage community engagement and the use of their logos for promotional purposes.</p>

<p>In the case of highly decentralized projects like Bitcoin or Dogecoin, where there is no single owner, the use of logos is considered open and free for all.</p>

<p>When you order a product featuring a specific coin's logo, please ensure that you use it according to the official logo usage guidelines. We trust our users to be aware of and adhere to the policies governing logo usage, as it is their responsibility to do so.</p>

<p>If you are a representative of a crypto project and you believe that your logo is being used in a way that is not in accordance with your project's branding guidelines, please contact us and we will remove the logo from our website as soon as possible.</p>

<h4>4. Privacy</h4>

<p>Please review our Privacy Policy, which also governs your visit to our website, to understand our practices.</p>

<h4>5. Production & Shipping</h4>

<p>Our partner Printful takes care of the production and shipping. With more than 17 million delivered orders, Printful provides a top of the class order fulfillment service.</p>

<p>Shipping is wordlwide.</p>

<p>The fixed base shipping fee is <b>10 USD</b> for regular international shipping with tracking. Every item's shipping is <b>+2 USD</b>. For hoodies and sweatshirts, every item's shipping is +4 USD. For mugs it's +5 USD. The final sum is shown in your shopping cart before the checkout.</p>

<p>Clothing production is done “on demand” – after the user makes an order. The average production time is 3 days.</p>

<p>After production is complete, regular shipping takes around 5-15 days.</p>

<h4>6. Returns and refunds</h4>

<p>Because each order is individual, we cannot change them to other products. It means we do not accept returns and provide no refunds.</p>

<p>In case the order was broken in transit or in any way not as described, we will change your item with a new one, in a timely manner, free of charge. In such situation, please contact us at &#109;&#101;&#114;&#99;&#104;&#64;&#99;&#114;&#121;&#112;&#116;&#111;&#108;&#111;&#103;&#111;&#115;&#46;&#99;&#99;, include a photo of the item and a short description about the situation.</p>

<p>In case our main service – ordering high quality crypto merchandise – was not fulfilled and it was a direct cause of Crypto Logos application malfunctioning, we guarantee that all steps will be taken to ensure that the service will be fulfilled as soon as possible without any additional costs involved for our users.&nbsp; </p>

<p>In cooperation with our partner Printful, we will do our best to provide a top quality order fulfillment service so that you could be perfectly happy with your order.</p>

<h4>7. Limitations</h4>

<p>In no event shall Crypto Logos or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Crypto Logos website, even if  Crypto Logos or it’s authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you. </p>

<h4>8. Accuracy of materials</h4>

<p>The materials appearing on Crypto Logos website could include technical, typographical, or photographic errors. Crypto Logos does not warrant that any of the materials on its website are accurate, complete or current. Crypto Logos may make changes to the materials contained on its website at any time without notice. However Crypto Logos does not make any commitment to update the materials.<br><br>It is a sole responsibility of the user to double-check that the logo provided on the Crypto Logos website is accurate and suits the individual need of the user.<br><br>Crypto Logos does its best to provide the up to date and accurate logo collection for its products. However Crypto Logos does not guarantee that the logos are 100% accurate or recent.</p>

<p>If you have spotted an inaccurate or an old version of a logo, let us know by email and we will  update the logo as soon as possible.</p>

<h4>9. Links</h4>

<p>Crypto Logos has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Crypto Logos of the site. Use of any such linked website is at the user’s own risk.</p>

<h4>10. Modifications</h4>

<p>Crypto Logos may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>

<h4>11. Governing Law</h4>

<p>These terms and conditions are governed by and construed in accordance with the laws of Lithuania and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>

</span>

<span v-if="this.$route.name == 'privacy-policy'">

<h3>Privacy Policy</h3>

<div style="height:5px;"></div>

<p>Last updated: May 7, 2020</p>

<p>Crypto Logos operates https://cryptologos.cc (the “Site”). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site. </p>

<p>Crypto Logos is a privacy-focused service. The Personal Information of the user is never shared with third parties.</p>

<p>If at some point Crypto Logos, as a whole business, will be sold, the transfer of the Personal Information of Crypto Logos users to the new business owner will be done under similar “privacy-oriented” conditions.</p>

<p>We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.</p>

<h4>Information Collection and Use</h4>

<p>For all visitors of the Site we collect standard Cookies and Log Data, as described in the next sections.&nbsp;</p>

<p>For payment processing, we use Stripe. When processing your payment, we never see your personal or payment details, as they are only collected by the Stripe service. For more details, please refer to the <a href="https://stripe.com/gb/privacy" target="_blank">Stripe privacy policy</a>.</p>

<h4>Log Data</h4>

<p>Like many site operators, we collect information that your browser sends whenever you visit our Site (“Log Data”).&nbsp;</p>

<p>This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.&nbsp;</p>

<p>In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this in order to provide user behavior insights and statistics for our website.</p>

<h4>Cookies</h4>

<p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive.&nbsp;</p>

<p>Like many sites, we use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

<h4>Security</h4>

<p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

<h4>Changes to This Privacy Policy</h4>

<p>This Privacy Policy is effective as of Apr 23, 2019 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page. </p>

<p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.&nbsp;</p>

<p>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.</p>

<h4>Contact Us</h4>

<p>If you have any questions about this Privacy Policy, please contact us.</p>

</span>


<span v-if="this.$route.name == 'shipping'">

<h3>Shipping</h3>

<p>Crypto Logos works together with <a href="https://www.printful.com/" target="_blank">Printful</a> which takes care of the production and shipping. With more than 17.3 million orders delivered, Printful provides the top of the class order fulfillment service.</p>

<p>Shipping is worldwide.</p>

<p>The base shipping price is <b>10 USD</b> for regular international shipping with tracking.</p>

<p>Every item's shipping is <b>+2 USD</b>.</p>

<p>For hoodies and sweatshirts, every item's shipping is +4 USD. For mugs it's +5 USD.</p>

<p>You will see the final sum in your shopping cart before the checkout.</p>

<p>All Crypto Logos products are produced "on-demand" - the production starts after the order was placed. The average production time is 3 business days.</p>

<p>After the production is complete, the shipping of your item(-s) takes around 5-10 days.</p>


<h3>Returns</h3>

<p>Since every Crypto Logos product is made "on-demand" and is based on individual requirements, we do not offer size, color or model changes after the production is complete, and in general do not accept returns. Please refer to the sizing chart (available on every product's page) before finalizing your order.</p>

<p>In case the package was broken in transit or a received product is somehow broken or not as described on the Crypto Logos website, please contact us at &#109;&#101;&#114;&#99;&#104;&#64;&#99;&#114;&#121;&#112;&#116;&#111;&#108;&#111;&#103;&#111;&#115;&#46;&#99;&#99;, include a photo of the item and a short description of the situation.</p>

<p>Claims deemed an error on our part are covered at our expense. Any claims for misprinted/damaged/defective items must be submitted (contact us by email) within 2 weeks after the product has been received. For packages lost in transit, all claims must be submitted no later than 2 weeks after the estimated delivery date.</p>

<p>You can find more details about each product on its individual page. For any additional questions, please contact us.</p>

</span>
<span v-if="this.$route.name == 'contacts'">

<h3>Contacts</h3>

<p>
  For any questions related to your order or the Crypto Logos store in general, please contact us by email.
  <br><br>
  <b>&#109;&#101;&#114;&#99;&#104;&#64;&#99;&#114;&#121;&#112;&#116;&#111;&#108;&#111;&#103;&#111;&#115;&#46;&#99;&#99;</b>
</p>

<div style="height:20px;"></div>

<!-- <p>Follow us on Twitter by following <a href="https://twitter.com/cryptologoscc" target="_blank">Crypto Logos</a> -->

<div class="desktop-only" style="height:50px;"></div>

</span>


<span v-if="this.$route.name == 'payment-success'">

<h1>Payment successful</h1>

<p>Thank you, we have received your order.</p>

</span>


<span v-if="this.$route.name == 'payment-declined'">

<h1>Payment declined</h1>

<p>Your payment was declined. You can try again. Please double-check the data you have entered, or use another card.</p>

</span>



    </template>
  </div>
</template>

<script>
import LogoHistory from './LogoHistory.vue'

export default {
  components: {
    LogoHistory,
  },

  // watch: {
  //   $route (to, from){
  //     if (to.name == 'about') {
  //       this.inner = "About";
  //       this.$emit('updateHead');
  //     }
  //     else if (to.name == 'history') {
  //       this.inner =  "History of Cryptocurrency Logos";
  //       this.$emit('updateHead');
  //     }
  //     else if (to.name == 'faq') {
  //       this.inner =  "FAQ";
  //       this.$emit('updateHead');
  //     }
  //     else if (to.name == 'guides') {
  //       this.inner =  "Brand Assets, Logo Kits, Style Books, Branding Examples, Inspiration";
  //       this.$emit('updateHead');
  //     }
  //   }
  // },

  data() {
    return {
      // title: 'Loading...'
      inner: ''
    }
  },

  // head: {
  //   title: function () {
  //     return {
  //       inner: this.inner,
  //       separator: '-',
  //       complement: 'Crypto Logos'
  //     }
  //   },
  // },

  // mounted() {
  //   if (this.$route.name == 'about') {
  //     this.inner =  "About";
  //     this.$emit('updateHead');
  //   }
  //   else if (this.$route.name == 'faq') {
  //     this.inner =  "FAQ";
  //     this.$emit('updateHead');
  //   }
  //   else if (this.$route.name == 'history') {
  //     this.inner =  "History of Cryptocurrency Logos";
  //     this.$emit('updateHead');
  //   }
  //   else if (this.$route.name == 'guides') {
  //     this.inner =  "Brand Assets, Logo Kits, Style Books, Branding Examples, Inspiration";
  //     this.$emit('updateHead');
  //   }
  // }
  
}
</script>

<style scoped>

  p { 
    font-size: 14px;
  }

  a {
    border-bottom: 1px solid grey;
  }
</style>